import * as React from 'react';
import Layout from '../components/Layout/Layout';
import * as styles from './index.module.scss';
// import { StaticImage } from 'gatsby-plugin-image';
import { IconContext } from 'react-icons';
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';
import { SiFastly } from 'react-icons/si';
import { FaTools, FaWallet } from 'react-icons/fa';
import { Heading } from '../components/Heading/Heading';
import { Accordion } from '../components/Accordion/Accordion';
import { Text } from '../components/Text/Text';
import Seo from '../components/Seo/Seo';
import clsx from 'clsx';
import { ImPhone } from 'react-icons/im';
import { Link } from 'gatsby';
import { BsArrowRightCircle as ArrowButton } from 'react-icons/bs';

const IndexPage = () => {
  return (
    <Layout>
      <Seo
        title={`Déboucheur de canalisation professionnel | Lège-Cap-Ferret (33236 /33950) | Fabrice Anton, Bassin Pro Service`}
        description={`Hydrocurage, débouchage et assainissement de canalisations, professionnel avec hydrocureur haute pression sur le Bassin d'Arcachon, le
        Sud Médoc et dans les villes périphériques: Audenge, Lanton, Cassy, Taussat, Andernos-les-Bains, Arès, Lège, Lège-Cap-Ferret, Claouey, Piquey, L'Herbe, Cap-Ferret, Lacanau, Blagon, Le Temple. Débouchage WC, Douche,
        Evier, Egout. Tarifs identiques dans toute la France. En urgence sous 24h sans majoration.`}
        lang={'fr'}
      />

      <div className='text-center'>
        <div className={styles.hero}>
          <div
            className={clsx({
              [`container`]: true,
            })}
          >
            {/* ============== */}
            <section
              className={clsx({
                [styles.banner]: true,
                ['row']: true,
              })}
            >
              <div className='col-12'>
                <Heading
                  tagName={'h2'}
                  styleType={'h2'}
                  textAlignment={'start'}
                  textColour={'white'}
                >
                  Besoin d'une entreprise professionnelle pour déboucher vos
                  canalisations ?
                </Heading>
                <Heading
                  tagName={'h2'}
                  styleType={'h2'}
                  textAlignment={'start'}
                  textColour={'white'}
                >
                  Appelez-nous au <br className='d-md-none' />
                  <a href='tel:0624093340' className={styles.buttonCall}>
                    06 24 09 33 40
                  </a>
                </Heading>
                <Text
                  tagName={'p'}
                  styleType={'h3'}
                  fontWeight={'bold'}
                  textAlignment={'start'}
                  text={`24H/24, 7 jours sur 7, 365 jours par an`}
                  textColour={'white'}
                />
              </div>
            </section>
          </div>
          {/* <StaticImage
            src='../images/hero-image-lavabo-2.jpg'
            alt='Un systeme de canalisation entretenu par un professionnel Fabrice Anton'
          /> */}
        </div>
      </div>
      <div className='container'>
        {/* ============== */}
        <section className='row justify-content-center' id='services'>
          <div className='col-12 col-xlL-10'>
            <header className={styles.sectionHeader}>
              <Heading
                tagName={'h2'}
                styleType={'h4'}
                textAlignment={'start'}
                text={'Bassin Pro Services, pour vous'}
                textColour={'secondary'}
              />
              <Heading
                tagName={'h3'}
                styleType={'h2'}
                textAlignment={'start'}
                text={`Expert en débouchage et assainissement`}
                textColour={'primary'}
              />
            </header>
            <Text tagName={'p'} styleType={'bodyRegular'}>
              À Bassin Pro Services, nous nous occupons des canalisations
              bouchées, de leur entretien et de leur réparation. Notre zone
              d'opération s'étend de la région du Nord Bassin au Sud Médoc,
              d'Audenge à Lacanau, jusqu'au Cap-Ferret.
            </Text>
            <Text tagName={'p'} styleType={'bodyRegular'}>
              Doté d'un hydrocureur de dernière génération, nous débouchons et
              assainissons vos conduits souterrains d'eaux usées.
            </Text>
            <ul className={styles.bulletList}>
              <li>
                <div className={styles.icon}>
                  <IconContext.Provider
                    value={{ size: '2em', color: '#15acdb' }}
                  >
                    <IoMdCheckmarkCircleOutline title='Icon intervention débouchage des conduits souterrains' />
                  </IconContext.Provider>
                </div>
                <Text styleType={'h5'} tagName={'span'} fontWeight={'bold'}>
                  Débouchage de réseaux d'assainissement en urgence
                </Text>
              </li>
              <li>
                <div className={styles.icon}>
                  <IconContext.Provider
                    value={{ size: '2em', color: '#15acdb' }}
                  >
                    <IoMdCheckmarkCircleOutline title='Icon entretien des canalisations' />
                  </IconContext.Provider>
                </div>
                <Text styleType={'h5'} tagName={'span'} fontWeight={'bold'}>
                  Curage et nettoyage de vos canalisations
                </Text>
              </li>
              <li>
                <div className={styles.icon}>
                  <IconContext.Provider
                    value={{ size: '2em', color: '#15acdb' }}
                  >
                    <IoMdCheckmarkCircleOutline title="Icon optimisation des évacuations d'eaux usées" />
                  </IconContext.Provider>
                </div>
                <Text styleType={'h5'} tagName={'span'} fontWeight={'bold'}>
                  Restauration du débit optimal des évacuations d'eaux usées
                </Text>
              </li>
              <li>
                <div className={styles.icon}>
                  <IconContext.Provider
                    value={{ size: '2em', color: '#15acdb' }}
                  >
                    <IoMdCheckmarkCircleOutline title='Icon intervention hydrocureur Nord Bassin' />
                  </IconContext.Provider>
                </div>
                <Text styleType={'h5'} tagName={'span'} fontWeight={'bold'}>
                  Entretien de vos conduits souterrains
                </Text>
              </li>
              <li>
                <div className={styles.icon}>
                  <IconContext.Provider
                    value={{ size: '2em', color: '#15acdb' }}
                  >
                    <IoMdCheckmarkCircleOutline title='Icon intervention professionnelle' />
                  </IconContext.Provider>
                </div>
                <Text styleType={'h5'} tagName={'span'} fontWeight={'bold'}>
                  Chez vous en un temps record
                </Text>
              </li>
            </ul>

            <Link to='/services' className={styles.linkButton}>
              <span className={styles.label}>
                En savoir plus à propos de nos services
              </span>
              <IconContext.Provider value={{ size: '1.5em', color: '#FFF' }}>
                <ArrowButton title='Icon intervention débouchage des conduits souterrains' />
              </IconContext.Provider>
            </Link>
          </div>
        </section>
        {/* ============== */}
        <section className='row justify-content-center' id='a-propos'>
          {/* <div className='col-12 col-lg-4'>
            <StaticImage
              src='../images/shot-one.png'
              alt='Un systeme de canalisation entretenu par un professionnel Fabrice Anton'
            />
          </div> */}
          <div className='col-12 col-xlL-10'>
            <header className={styles.sectionHeader}>
              <Heading
                tagName={'h2'}
                styleType={'h4'}
                textAlignment={'start'}
                text={'À propos de nous'}
                textColour={'secondary'}
              />
              <Heading
                tagName={'h3'}
                styleType={'h2'}
                textAlignment={'start'}
                text={`15 ans d'expérience professionnelle dans la plomberie`}
                textColour={'primary'}
              />
            </header>
            <Text tagName={'p'} styleType={'bodyRegular'}>
              Notre approche est simple. Vous fournir un prix compétitif avec
              l'intervention d'un spécialiste qualifié sans coûts masqués.
            </Text>
            <Text tagName={'p'} styleType={'bodyRegular'}>
              Fabrice Anton, directeur de Bassin Pro Services, est riche d'une
              quinzaine d'années d'expérience professionnelle. Il bénéficie
              d'une totale connaissance des réseaux humides, de gaz et d'eau
              potable.
            </Text>
            <Text tagName={'p'} styleType={'bodyRegular'}>
              Nous intervenons et débouchons vos canalisations dans les plus
              brefs délais.
            </Text>
            <Text tagName={'p'} styleType={'bodyRegular'}>
              Dès réception de votre appel, nous mettrons tout en œuvre pour
              nous rendre chez vous, diagnostiquer et réparer vos évacuations
              bouchées.
            </Text>

            <Link to='/a-propos' className={styles.linkButton}>
              <span className={styles.label}>En savoir plus à note propos</span>
              <IconContext.Provider value={{ size: '1.5em', color: '#FFF' }}>
                <ArrowButton title='Icon intervention débouchage des conduits souterrains' />
              </IconContext.Provider>
            </Link>

            <section>
              <Heading
                tagName={'h3'}
                styleType={'h2'}
                textAlignment={'start'}
                text={`Un hydrocureur de dernière génération`}
                textColour={'primary'}
              />

              <Text tagName={'p'} styleType={'bodyRegular'}>
                Nous travaillons avec du matériel de haute qualité nous
                permettant d'assainir l'ensemble de votre réseau, en venant chez
                vous, nous vous assurons de résoudre votre problème.
              </Text>

              <Text tagName={'p'} styleType={'bodyRegular'}>
                Une fois sur place, nous évaluons et diagnostiquons le problème.
                En fonction du résultat et après concertation, nous pouvons
                inspecter vos évacuations à l'aide d'une caméra pour localiser
                précisément les bouchons empêchant le bon écoulement de votre
                réseau. Enfin, nous déployons l'hydrocureur en utilisant les
                tuyaux appropriés. L'hydrocureur injecte alors un jet d'eau
                douce puissant, avec des vitesses saccadées, qui fragmente et
                élimine les bouchons et résidus. Une fois terminé, vos canaux
                souterrains sont libérés et l'évacuation est optimale.
              </Text>
              <br />

              <div className={styles.assetsHydrocureur}>
                <Heading
                  tagName={'h2'}
                  styleType={'h2'}
                  textAlignment={'start'}
                  text={`Quelques chiffres :`}
                  textColour={'white'}
                />
                <br />
                <div className='row text-md-center'>
                  <div className='col-12 col-md-4'>
                    <Heading
                      tagName={'h3'}
                      styleType={'h2'}
                      text={`60 mètres`}
                      textColour={'tertiary'}
                    />
                    <Text
                      tagName={'p'}
                      styleType={'h5'}
                      textColour={'white'}
                      fontWeight={'bold'}
                    >
                      de tuyaux adaptés
                    </Text>
                  </div>
                  <div className='col-12 col-md-4'>
                    <Heading
                      tagName={'h3'}
                      styleType={'h2'}
                      text={`160 bars`}
                      textColour={'tertiary'}
                    />
                    <Text
                      tagName={'p'}
                      styleType={'h5'}
                      textColour={'white'}
                      fontWeight={'bold'}
                    >
                      de hautes pressions (kg/cm²)
                    </Text>
                  </div>
                  <div className='col-12 col-md-4'>
                    <Heading
                      tagName={'h3'}
                      styleType={'h2'}
                      text={`1 Caméra`}
                      textColour={'tertiary'}
                    />
                    <Text
                      tagName={'p'}
                      styleType={'h5'}
                      textColour={'white'}
                      fontWeight={'bold'}
                    >
                      pour l'exploration
                    </Text>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      </div>
      {/* ============== */}
      <div className={styles.heroPage} id='offres'>
        <div className='container'>
          <div className='row  text-center justify-content-center'>
            <div className='col-12 mb-4 col-md-8'>
              <header className={styles.sectionHeader}>
                <Heading
                  tagName={'h2'}
                  styleType={'h4'}
                  textAlignment={'center'}
                  text={'Notre offre'}
                  textColour={'tertiary'}
                />
                <Heading
                  tagName={'h3'}
                  styleType={'h2'}
                  textAlignment={'center'}
                  text={`Une intervention rapide et de qualité`}
                  textColour={'white'}
                />
              </header>
              <Text tagName={'p'} styleType={'h5'}>
                Actif sur la région du Nord Bassin au Sud Médoc, nous sommes
                disponibles pour intervenir sur vos évacuations d'Audenge à
                Lacanau, jusqu'au Cap-Ferret.
              </Text>
            </div>
          </div>
          <div className='row  text-center'>
            <div className='col-12 col-md-4 mb-3 mb-md-0'>
              <div className={styles.badgeWrapper}>
                <div className={styles.badge}>
                  <IconContext.Provider
                    value={{ size: '2.5em', color: '#0277bd' }}
                  >
                    <SiFastly title='Icon intervention débouchage canalisation rapide' />
                  </IconContext.Provider>
                </div>
              </div>
              <Heading
                tagName={'h4'}
                styleType={'h4'}
                textAlignment={'center'}
                text={`Intervention rapide`}
                textColour={'tertiary'}
              />
              <Text tagName={'h5'} fontWeight={'bold'} styleType={'h5'}>
                Chez vous dès que possible
              </Text>
            </div>

            <div className='col-12 col-md-4 mb-3 mb-md-0'>
              <div className={styles.badgeWrapper}>
                <div className={styles.badge}>
                  <IconContext.Provider
                    value={{ size: '2.5em', color: '#0277bd' }}
                  >
                    <FaTools title='Icon Fabrice Anton expert en assainissement de canalisations' />
                  </IconContext.Provider>
                </div>
              </div>
              <Heading
                tagName={'h4'}
                styleType={'h4'}
                textAlignment={'center'}
                text={`Un expert pour vous`}
                textColour={'tertiary'}
              />
              <Text tagName={'h5'} fontWeight={'bold'} styleType={'h5'}>
                Un hydrocureur à très haute pression
              </Text>
            </div>

            <div className='col-12 col-md-4 mb-3 mb-md-0'>
              <div className={styles.badgeWrapper}>
                <div className={styles.badge}>
                  <IconContext.Provider
                    value={{ size: '2.5em', color: '#0277bd' }}
                  >
                    <FaWallet title='Icon Fabrice Anton expert des conduits souterrains de plomberie' />
                  </IconContext.Provider>
                </div>
              </div>
              <Heading
                tagName={'h4'}
                styleType={'h4'}
                textAlignment={'center'}
                text={`Un coût juste`}
                textColour={'tertiary'}
              />
              <Text tagName={'h5'} fontWeight={'bold'} styleType={'h5'}>
                Avec un expert plombier
              </Text>
            </div>
          </div>
        </div>
      </div>
      {/* ============== */}
      {/* ============== */}

      <div className='container'>
        <section className='row justify-content-center'>
          <div className='col-12 col-xlL-10'>
            <header className={styles.sectionHeader}>
              <Heading
                tagName={'h2'}
                styleType={'h4'}
                textAlignment={'start'}
                text={'Nos atouts'}
                textColour={'secondary'}
              />
              <Heading
                tagName={'h3'}
                styleType={'h2'}
                textAlignment={'start'}
                text={`Expert en débouchage de canalisations`}
                textColour={'primary'}
              />
            </header>
            <Text tagName={'p'} styleType={'bodyRegular'}>
              Ancien canalisateur des travaux publiques dans l'assainissement,
              nous avons été en charge de la pose de nombreux réseaux et de leur
              entretien au nom des Chantiers d'Aquitaine, qui accompagne ses
              clients dans leurs projets de remplacement, de réhabilitation ou
              de création de réseaux humides, de gaz et d'eau potable.
            </Text>
            <Text tagName={'p'} styleType={'bodyRegular'}>
              En nous contactant, vous avez la garantie d’une prestation
              exécutée avec sérieux et professionnalisme.
            </Text>

            <Link to='/contact' className={styles.linkButton}>
              <span className={styles.label}>Nous contacter</span>
              <IconContext.Provider value={{ size: '1.5em', color: '#FFF' }}>
                <ArrowButton title='Icon intervention débouchage des conduits souterrains' />
              </IconContext.Provider>
            </Link>
          </div>
        </section>
      </div>
      <div className='container'>
        <section className='row justify-content-center' id='faqs'>
          <div className='col-12 col-xlL-10 mb-3'>
            <header className={styles.sectionHeader}>
              <Heading
                tagName={'h2'}
                styleType={'h4'}
                textAlignment={'start'}
                text={'Des questions ?'}
                textColour={'secondary'}
              />
              <Heading
                tagName={'h3'}
                styleType={'h2'}
                textAlignment={'start'}
                text={`FAQS`}
                textColour={'primary'}
              />
            </header>

            <Text tagName={'p'} styleType={'bodyRegular'}>
              Pour répondre au mieux à vos attentes, voici quelques questions
              fréquentes auxquelles nous vous apportons des réponses :
            </Text>

            <br />
            <Accordion
              width={12}
              content={[
                {
                  question: 'En combien de temps intervenez-vous ?',
                  answer: `Dès réception de votre appel nous mettons tout en œuvre pour arriver chez vous au plus vite, notre camion contient tout le matériel professionnel nécessaire pour assainir vos canalisations.

                  En fonction de notre emploi du temps, nous sommes chez vous en moins de 2 heures.`,
                },
                {
                  question:
                    'Comment procédez-vous pour déboucher nos canalisations obstruées ?',
                  answer: `À notre arrivée, nous évaluons et diagnostiquons votre problème.  En fonction de la situation et après concertation, nous pouvons inspecter vos évacuations à l'aide d'une caméra pour localiser précisément les bouchons empêchant le bon écoulement de votre réseau.
                  Enfin, nous déployons l'hydrocureur en utilisant les tuyaux appropriés. L'hydrocureur injecte alors un jet d'eau douce puissant, avec des vitesses saccadées, qui fragmente et élimine les bouchons et résidus. Une fois notre débouchage effectué, vos canaux souterrains sont libérés et l'évacuation est optimale.`,
                },
                {
                  question: 'Utilisez-vous des produits chimiques ?',
                  answer: `Notre hydrocureur dernière génération utilise uniquement de l'eau douce. Nous prenons en compte le respect de l'environnement et n'endommageons pas la nappe phréatique.`,
                },
                {
                  question: `Est-il nécessaire d'entretenir nos canalisations ? `,
                  answer: `Il est effectivement très important d'entretenir vos conduits souterrains. En cas de problème, les canalisations bouchées peuvent provoquer un dégât des eaux sérieux dans votre habitation. Pour éviter tout problème, nous vous conseillons de nous appeler en moyenne une fois tous les deux ans.`,
                },
                {
                  question: `Dans quel secteur géographique travaillez-vous ? `,
                  answer: `Nous travaillons dans la région du Nord Bassin au Sud Médoc. Nous venons dans les villes suivantes: Audenge, Lanton, Cassy, Taussat, Andernos-les-Bains, Arès, Lège, Lège-Cap-Ferret, Claouey, Piquey, L'Herbe, Cap-Ferret, Blagon, Le Temple, Lacanau, Le Porge.`,
                },
                {
                  question: `Pouvons-nous prendre rendez-vous ? `,
                  answer: `Nous sommes disponibles et joignables en permanence, un simple appel nous permettra d'organiser notre visite pour l'inspection de vos canalisations.`,
                },
              ]}
            />

            <br />
            <a href='tel:0624093340' className={styles.buttonPhoneCall}>
              <ImPhone role='img' title='phone' />
              <span className='d-inline d-md-none'>06 24 09 33 40</span>
              <span className='d-none d-md-inline'>
                Appelez-nous au 06 24 09 33 40
              </span>
            </a>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default IndexPage;
