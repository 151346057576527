import React, { FC, useState } from 'react';

import { AccordionPanel } from '../AccordionPanel/AccordionPanel';

import * as styles from './Accordion.module.scss';

export type IndicatorType = 'chevron' | 'radio';

export const Accordion = ({
  content,
  width = 8,
  filterKey,
  indicator = 'chevron',
  selectedItemId,
  onChange,
}) => {
  const [activeIndex, setActiveIndex] = useState(
    selectedItemId > -1 ? selectedItemId : null
  );
  const contentAccordion = filterKey
    ? content.filter((item) => item[filterKey])
    : content;

  const handleIndexChange = (index) => {
    setActiveIndex(index);
    if (onChange) {
      onChange(index);
    }
  };

  return (
    <div className={`${styles.accordionWrapper}`}>
      <div className={styles.accordion} role='tree'>
        {contentAccordion.map((item, index) => {
          return (
            <AccordionPanel
              key={`panel-${index}`}
              item={item}
              index={index}
              isOpen={index === activeIndex}
              indicator={indicator as IndicatorType}
              onClick={() => {
                activeIndex !== index
                  ? handleIndexChange(index)
                  : handleIndexChange(null);
              }}
            />
          );
        })}
      </div>
    </div>
  );
};
