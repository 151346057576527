import { ResizeObserver } from '@juggle/resize-observer';
import clsx from 'clsx';
import React, { MouseEventHandler } from 'react';
import { animated, useSpring } from 'react-spring';
import useMeasure from 'react-use-measure';

import * as styles from './AccordionPanel.module.scss';
import { Heading } from '../Heading/Heading';
import { Text } from '../Text/Text';
import { IconContext } from 'react-icons';
import { BsArrowUpCircle } from 'react-icons/bs';

export const AccordionPanel = ({ item, index, onClick, isOpen, indicator }) => {
  const [ref, bounds] = useMeasure({ polyfill: ResizeObserver });
  const { height } = bounds;

  const animationPanel = useSpring({
    overflow: 'hidden',
    // padding not taken into account hence: + 32 (top: 16px / bottom: 16px)
    height: isOpen ? height + 32 : 0,
    opacity: isOpen ? 100 : 0,
  });

  const animationCaret = useSpring({
    width: '16px',
    height: '16px',
    transformOrigin: 'center',
    transform: `rotate(${isOpen ? -180 : 0}deg)`,
  });

  return (
    <div
      role='treeitem'
      data-testid={`accordion-panel`}
      //   aria-expanded={isOpen}
    >
      <div
        id={`panel-${index}`}
        data-testid={`panel-item-${index}`}
        className={styles.header}
        // aria-controls={`panel-content-${index}`}
        onClick={onClick as MouseEventHandler}
      >
        <Heading
          tagName={'h3'}
          styleType={'h5'}
          textAlignment={'start'}
          text={item.question}
          textColour={isOpen ? 'primary' : ''}
        />

        <div>
          {indicator === 'chevron' && (
            <animated.div style={animationCaret}>
              <IconContext.Provider value={{ color: '#0f152f' }}>
                <BsArrowUpCircle title='Icon intervention débouchage des conduits souterrains' />
              </IconContext.Provider>
            </animated.div>
          )}

          {indicator === 'radio' && (
            <div
              className={clsx({
                [styles.radioPositionner]: true,
                [styles.radioPositionnerActive]: isOpen,
              })}
            ></div>
          )}
        </div>
      </div>
      <div
        className={styles.body}
        id={`panel-content-${index}`}
        role='region'
        aria-labelledby={`panel-${index}`}
      >
        <animated.div style={animationPanel}>
          <div
            id={`faq${index + 1}_desc`}
            ref={ref}
            className={styles.bodyInner}
            // aria-hidden={!isOpen}
            data-testid='copy-accordion-panel'
          >
            <Text
              tagName={'p'}
              styleType={'bodyRegular'}
              textAlignment={'start'}
            >
              {item.answer}
            </Text>
          </div>
        </animated.div>
      </div>
    </div>
  );
};
